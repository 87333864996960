<mat-card class="fx-card fx-fill relative">
	<div class="fx-fill fx-layout-column-nowrap">
		<mat-toolbar
			class="fx-card-toolbar fx-height-42 fx-layout-row-nowrap fx-content-space-between fx-items-align"
			color="primary">
			<mat-icon fontIcon="mdi-note" fontSet="mdi"></mat-icon>
			<span>{{ worklist | translate }}</span>

			<span class="fx-grow-1"></span>
			<span class="fz-14 fw-500">{{ currentDate }}</span>
			<span class="fx-grow-1"></span>
		</mat-toolbar>

		<div
			class="fx-search-area fx-layout-row fx-content-end fx-items-end fx-gap-4 fx-padding-8"
			[formGroup]="filterForm">
			<mat-form-field subscriptSizing="dynamic">
				<mat-icon
					matIconPrefix
					fontIcon="mdi-magnify"
					fontSet="mdi"></mat-icon>
				<input
					#filter
					formControlName="key"
					matInput
					placeholder="{{ 'SEARCH' | translate }}" />
			</mat-form-field>

			<mat-form-field subscriptSizing="dynamic" class="fx-grow-1">
				<mat-label>{{ 'LABELS' | translate }}</mat-label>
				<mat-chip-grid
					#chipList
					aria-label="Label selection"
					class="ft-labels-filter">
					@for (label of labels; track $index) {
						<mat-chip-row
							class="ft-mdc-chip"
							(removed)="remove(label)"
							[style.background-color]="label?.color">
							{{ label?.value }}
							<button matChipRemove>
								<mat-icon
									fontIcon="mdi-close"
									fontSet="mdi"></mat-icon>
							</button>
						</mat-chip-row>
					}
					<input
						[placeholder]="'LABEL' | translate"
						#labelInput
						[formControl]="labelCtrl"
						[matAutocomplete]="auto"
						[matChipInputFor]="chipList"
						(matChipInputTokenEnd)="add($event)"
						class="ft-chip-input" />
				</mat-chip-grid>
				<mat-autocomplete
					#auto="matAutocomplete"
					(optionSelected)="selected($event)"
					[autoActiveFirstOption]="true"
					autoSelectActiveOption="true">
					@for (label of filteredLabels; track $index) {
						<mat-option [value]="label.value">
							{{ label.value }}
						</mat-option>
					}
				</mat-autocomplete>
			</mat-form-field>

			<div class="fx-layout-column-nowrap">
				<h5 style="margin: 6px 0">{{ 'PRIORITY' | translate }}</h5>
				<mat-button-toggle-group
					aria-label="priorities"
					formControlName="priority"
					multiple
					name="priority">
					@for (priority of priorities; track $index) {
						<mat-button-toggle
							[matTooltip]="priority.value"
							[value]="priority.id">
							<mat-icon
								fontIcon="mdi-circle"
								[ngStyle]="{
									background:
										'linear-gradient(45deg, black, ' +
										priority.color +
										', white)',
									'-webkit-background-clip': 'text',
									'-webkit-text-fill-color': 'transparent'
								}"
								fontSet="mdi"></mat-icon>
						</mat-button-toggle>
					}
				</mat-button-toggle-group>
			</div>

			<div class="fx-layout-column-nowrap">
				<h5 style="margin: 6px 0">{{ 'REPORT_STATUS' | translate }}</h5>
				<mat-button-toggle-group
					aria-label="Report status"
					formControlName="reportStatus"
					multiple
					name="reportStatus">
					@for (status of reportStatuses; track $index) {
						<mat-button-toggle [value]="status.value">
							<mat-icon
								[fontIcon]="status.icon"
								[matTooltip]="status.value | translate"
								[ngStyle]="{
									background:
										'linear-gradient(45deg, black, ' +
										status.color +
										', white)',
									'-webkit-background-clip': 'text',
									'-webkit-text-fill-color': 'transparent'
								}"
								fontSet="mdi"></mat-icon>
						</mat-button-toggle>
					}
				</mat-button-toggle-group>
			</div>

			@if (generalSetting && generalSetting.billingRequired) {
				<div class="fx-layout-column-nowrap">
					<h5 style="margin: 6px 0">
						{{ 'PAYMENT_STATUS' | translate }}
					</h5>
					<mat-button-toggle-group
						aria-label="payment status"
						formControlName="paymentStatus"
						multiple
						name="paymentStatus">
						@for (status of paymentStatuses; track $index) {
							<mat-button-toggle [value]="status.value">
								<mat-icon
									[fontIcon]="status.icon"
									[matTooltip]="status.value | translate"
									[ngStyle]="{
										background:
											'linear-gradient(45deg, black, ' +
											status.color +
											', white)',
										'-webkit-background-clip': 'text',
										'-webkit-text-fill-color': 'transparent'
									}"
									fontSet="mdi"></mat-icon>
							</mat-button-toggle>
						}
					</mat-button-toggle-group>
				</div>
			}

			@if (group.value === 'OT') {
				<div
					class="fx-layout-row-nowrap fx-content-start fx-items-end fx-gap-4">
					<mat-form-field
						subscriptSizing="dynamic"
						style="width: 208px">
						<mat-date-range-input [rangePicker]="picker">
							<input
								[placeholder]="'FROM' | translate"
								formControlName="startDate"
								matStartDate
								(dateInput)="changePeriod()" />
							<input
								[placeholder]="'TO' | translate"
								formControlName="endDate"
								matEndDate
								(dateInput)="changePeriod()" />
						</mat-date-range-input>
						<mat-datepicker-toggle
							[for]="picker"
							matSuffix></mat-datepicker-toggle>
						<mat-date-range-picker
							#picker
							(closed)="changePeriod()"></mat-date-range-picker>
					</mat-form-field>
				</div>
			}

			<mat-button-toggle-group
				#group="matButtonToggleGroup"
				(change)="changeRange($event)"
				aria-label="Period"
				formControlName="period"
				name="period">
				<mat-button-toggle value="TODAY">
					{{ 'TODAY' | translate }}
				</mat-button-toggle>
				<mat-button-toggle value="3D">
					{{ 'THREE_DAYS' | translate }}
				</mat-button-toggle>
				<mat-button-toggle value="1W">
					{{ 'ONE_WEEK' | translate }}
				</mat-button-toggle>
				<mat-button-toggle value="2W">
					{{ 'TWO_WEEKS' | translate }}
				</mat-button-toggle>
				<mat-button-toggle value="1M">
					{{ 'ONE_MONTH' | translate }}
				</mat-button-toggle>
				<mat-button-toggle value="3M">
					{{ 'THREE_MONTHS' | translate }}
				</mat-button-toggle>
				<mat-button-toggle value="OT">
					{{ 'ALL' | translate }}
				</mat-button-toggle>
			</mat-button-toggle-group>
		</div>

		<div class="fx-grow-1" style="overflow: auto">
			<mat-table
				[dataSource]="dataSource"
				[matSortActive]="sorting.active"
				[matSortDirection]="sorting.direction"
				[trackBy]="trackById"
				class="fire-table"
				matSort
				[matSortDisableClear]="true">
				<!--Table-->
				@for (col of displayedColumns; track $index) {
					<ng-container matColumnDef="{{ col.label }}">
						<mat-header-cell
							*matHeaderCellDef
							[disabled]="!col.sortable"
							mat-sort-header="{{ col.sortField }}">
							{{ col.header | translate }}
						</mat-header-cell>

						@if (col.unit === 'comment') {
							<mat-cell *matCellDef="let row">
								@if (row.assigningComment) {
									<button
										(click)="openDetails(row)"
										[matTooltip]="
											(userId === row.performerNameId
												? 'OPEN_PARAM'
												: 'ASSIGNED_FROM'
											)
												| translate
													: {
															value: physicians[
																row
																	.performerNameId
															]
													  }
										"
										mat-icon-button
										matTooltipClass="assignee-tooltip">
										<mat-icon
											[fontIcon]="getRowIcon(row)"
											[ngStyle]="{
												color:
													userId ===
													row.performerNameId
														? '#ff65d1'
														: '#03A9F4'
											}"
											fontSet="mdi"></mat-icon>
									</button>
								}
							</mat-cell>
						}

						@if (col.unit === 'prio') {
							<mat-cell *matCellDef="let row">
								<mat-icon
									fontIcon="mdi-circle"
									[matTooltip]="
										priorityMap[row[col.value]] | translate
									"
									[ngStyle]="{
										background:
											'linear-gradient(45deg, black, ' +
											getPriorityColor(
												priorityMap[row[col.value]]
											) +
											', white)',
										'-webkit-background-clip': 'text',
										'-webkit-text-fill-color': 'transparent'
									}"
									fontSet="mdi"></mat-icon>
							</mat-cell>
						}

						@if (col.unit === 'payment') {
							<mat-cell *cdkCellDef="let row">
								<mat-icon
									[fontIcon]="
										getPaymentStatusIcon(row[col.label])
									"
									[matTooltip]="row[col.label] | translate"
									matTooltipPosition="left"
									[ngStyle]="{
										background:
											'linear-gradient(45deg, black, ' +
											getPaymentStatusColor(
												row[col.label]
											) +
											', white)',
										'-webkit-background-clip': 'text',
										'-webkit-text-fill-color': 'transparent'
									}"
									fontSet="mdi"></mat-icon>
							</mat-cell>
						}

						@if (col.unit === 'history') {
							<mat-cell *cdkCellDef="let row">
								@if (row[col.label]) {
									<mat-icon
										[matTooltip]="row[col.label]"
										color="warn"
										fontIcon="mdi-account-clock"
										fontSet="mdi"
										matTooltipClass="med-history"></mat-icon>
								}
							</mat-cell>
						}

						@if (col.unit === 'date') {
							<mat-cell *matCellDef="let row">
								{{ formatDate(row[col.label]) }}
							</mat-cell>
						}
						@if (col.unit === 'status') {
							<mat-cell *matCellDef="let row">
								<mat-icon
									(click)="
										$event.stopPropagation();
										openReport(row.id)
									"
									[fontIcon]="getStatusIcon(row[col.label])"
									[matTooltip]="row[col.label] | translate"
									matTooltipPosition="left"
									[ngStyle]="{
										cursor: 'pointer',
										background:
											'linear-gradient(45deg, black, ' +
											getStatusColor(row[col.label]) +
											', white)',
										'-webkit-background-clip': 'text',
										'-webkit-text-fill-color': 'transparent'
									}"
									fontSet="mdi"></mat-icon>
							</mat-cell>
						}
						@if (col.unit === 'pathology') {
							<mat-cell
								*matCellDef="let row"
								[matTooltip]="row[col.label]">
								{{ row[col.label] }}
							</mat-cell>
						}
						@if (col.unit === 'labels') {
							<mat-cell
								*matCellDef="let row"
								class="fx-layout-row-nowrap"
								style="overflow: inherit">
								@if (!visibleLabels[row.id]) {
									<div
										class="fx-layout-row-nowrap fx-content-start fx-items-center fx-gap-2">
										@for (
											label of row[col.label]
												| slice: [0, 2];
											track $index
										) {
											<span
												class="label-badge"
												[style.background-color]="
													label.color
												"
												>{{ label.value }}</span
											>
										}
										@if (
											(row[col.label] | slice: [2, 10])
												?.length > 0
										) {
											<button
												class="label-badge-btn fx-layout-row-nowrap fx-items-center"
												(mouseover)="showLabels(row.id)"
												(focus)="
													$event.preventDefault()
												"
												mat-icon-button>
												<mat-icon
													fontSet="mdi"
													fontIcon="mdi-plus"></mat-icon>
												<span
													style="
														position: relative;
														top: -8px;
													"
													>{{
														(
															row[col.label]
															| slice: [2, 10]
														)?.length
													}}</span
												>
											</button>
										}
									</div>
								}
								@if (visibleLabels[row.id]) {
									<div
										class="labels-popup"
										(mouseleave)="
											visibleLabels[row.id] = false
										">
										@for (
											label of row[col.label];
											track $index
										) {
											<span
												class="label-badge"
												[style.background-color]="
													label.color
												"
												>{{ label.value }}</span
											>
										}
									</div>
								}
							</mat-cell>
						}
						@if (col.unit === 'FULL_NAME') {
							<mat-cell *matCellDef="let row">
								{{ showConfData(row, col.label) }}
							</mat-cell>
						}
						@if (col.unit === 'physician') {
							<mat-cell *matCellDef="let row">
								{{ physicians[row[col.label]] }}
							</mat-cell>
						}
						@if (
							![
								'date',
								'comment',
								'history',
								'payment',
								'status',
								'FULL_NAME',
								'prio',
								'labels',
								'pathology'
							].includes(col.unit)
						) {
							<mat-cell *matCellDef="let row">
								{{ row[col.label] }}</mat-cell
							>
						}
					</ng-container>
				}

				<!--actions-->
				<ng-container matColumnDef="action" stickyEnd>
					<mat-header-cell *matHeaderCellDef></mat-header-cell>
					<mat-cell
						*matCellDef="let row"
						class="fx-layout-row-nowrap fx-content-end fx-items-center"
						style="width: 120px">
						@if (can(row, 'editReport')) {
							<button
								(click)="openReport(row.id)"
								[matTooltip]="'OPEN' | translate"
								mat-icon-button>
								<mat-icon
									class="open-report-icon"
									fontIcon="mdi-folder-open"
									fontSet="mdi"></mat-icon>
							</button>
						}
						@if (can(row, 'editReport')) {
							<button
								(click)="openReport(row.id, true)"
								[matTooltip]="'OPEN_NEW_TAB' | translate"
								mat-icon-button>
								<mat-icon
									class="open-report-tab-icon"
									fontIcon="mdi-open-in-new"
									fontSet="mdi"></mat-icon>
							</button>
						}
						<button [matMenuTriggerFor]="menu" mat-icon-button>
							<mat-icon
								fontIcon="mdi-dots-horizontal"
								fontSet="mdi"></mat-icon>
						</button>
						<mat-menu #menu="matMenu" [overlapTrigger]="true">
							@if (false) {
								<button
									(click)="generateReport(row)"
									[disabled]="!can(row, 'editReport')"
									mat-menu-item>
									<mat-icon
										fontIcon="mdi-file"
										fontSet="mdi"></mat-icon>
									{{ 'NORMAL_REPORT' | translate }}
								</button>
							}
							@if (can(row, 'printReport') && canPrint(row)) {
								<button
									(click)="printReportingTask(row)"
									mat-menu-item>
									<mat-icon
										fontIcon="mdi-printer"
										fontSet="mdi"></mat-icon>
									{{ 'PRINT_REPORT' | translate }}
								</button>
							}
							@if (canAssign(row)) {
								<button
									(click)="assignPerformer(row)"
									mat-menu-item>
									<mat-icon
										fontIcon="mdi-account-arrow-right"
										fontSet="mdi"></mat-icon>
									{{ 'DELEGATE_TASK' | translate }}
								</button>
							}
							@if (canAssign(row)) {
								<button
									(click)="
										assignPerformer(row, 'RE_ASSIGN_TASK')
									"
									mat-menu-item>
									<mat-icon
										fontIcon="mdi-account-convert"
										fontSet="mdi"></mat-icon>
									{{ 'RE_ASSIGN_TASK' | translate }}
								</button>
							}
							<button (click)="addComment(row)" mat-menu-item>
								<mat-icon
									fontIcon="mdi-comment-alert"
									fontSet="mdi"></mat-icon>
								{{ 'ADD_COMMENT' | translate }}
							</button>
							<mat-divider></mat-divider>
							@if (
								can(row, 'deleteReport') &&
								row.reportingStatus !== 'DELIVERED'
							) {
								<button
									(click)="deleteReportingTask(row)"
									mat-menu-item>
									<mat-icon
										fontIcon="mdi-delete"
										fontSet="mdi"></mat-icon>
									{{ 'DELETE' | translate }}
								</button>
							}
						</mat-menu>
					</mat-cell>
				</ng-container>

				<!--header-->
				<mat-header-row
					*matHeaderRowDef="cols; sticky: true"></mat-header-row>
				<mat-row
					[@rowsAnimation]=""
					*matRowDef="let row; columns: cols">
				</mat-row>
			</mat-table>

			<div
				[style.display]="resultsLength === 0 ? '' : 'none'"
				class="fx-table-empty">
				@if (isLoadingResults) {
					<div>
						<mat-spinner
							[diameter]="50"
							[strokeWidth]="3"
							color="warn"></mat-spinner>
					</div>
				} @else {
					{{ 'NOITEMSFOUND' | translate }}
				}
			</div>
		</div>

		<mat-paginator
			[length]="resultsLength"
			[pageIndex]="0"
			[pageSizeOptions]="[10, 20, 50, 100]"
			[pageSize]="50"
			[showFirstLastButtons]="true">
		</mat-paginator>
	</div>
</mat-card>
