<mat-toolbar class="fx-height-42 fz-16" color="primary">
  <mat-icon fontSet="mdi" fontIcon="mdi-microphone"></mat-icon>
  <h3 class="fz-16" style="padding-left: 4px">
    {{ "RECORDER" | translate }}
  </h3>
  <span class="fx-grow-1"></span>
  <button mat-icon-button matDialogClose="null" tabindex="-1">
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>
</mat-toolbar>

<div id="ft-recorder" class="fx-padding-24">
  <div class="fx-layout-column-nowrap">
    <div
      class="fx-layout-row-nowrap fx-content-space-around fx-items-start"
      [style.margin-bottom.px]="12"
    >
      <button
        id="start"
        [disabled]="isPlay"
        style="padding: 0"
        mat-button
        color="primary"
        tabindex="-1"
        (click)="startRecording()"
      >
        <mat-icon fontIcon="mdi-record-rec" fontSet="mdi"></mat-icon>
        {{ "RECORD" | translate }}
      </button>
      <button
        id="stop"
        [disabled]="isStop"
        style="padding: 0"
        mat-button
        color="warn"
        tabindex="-1"
        (click)="pauseRecording()"
      >
        <mat-icon fontIcon="mdi-pause" fontSet="mdi"></mat-icon>
        {{ "PAUSE" | translate }}
      </button>
    </div>

    <div
      class="timer"
      [style.color]="isPlay ? 'green' : isStop ? 'dimgrey' : 'red'"
    >
      {{ getTimerString(timer) }}
    </div>
    @for (audio of audioFiles; track $index) {
    <div>
      <div class="fx-layout-row-nowrap fx-content-start fx-items-center">
		  <ft-audio-player2 [audioFile]="audio"></ft-audio-player2>
        <button mat-icon-button (click)="deleteRecord(audio)">
          <mat-icon
            [style.color]="'red'"
            fontSet="mdi"
            fontIcon="mdi-delete"
          ></mat-icon>
        </button>
      </div>
    </div>
    }
  </div>
</div>

@if (saving) {
<mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
}

<div matDialogActions align="end">
  <button mat-raised-button color="warn" [mat-dialog-close]="null">
    {{ "CANCEL" | translate }}
  </button>
  @if (audioFiles.length) {
  <button
    mat-raised-button
    color="primary"
    (click)="saving = true; saveFile()"
    [disabled]="saving"
  >
    {{ "SAVE" | translate }}
  </button>
  }
</div>
