import {
  catchError,
  debounceTime,
  first,
  map,
  mergeMap,
  startWith,
  switchMap,
} from "rxjs/operators";
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { ReportingService } from "../reporting.service";

import { assign, find, get, reverse, sortBy } from "lodash";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  CommandService,
  DictationDTO,
  GeneralSettingDTO,
  Image,
  LabelDTO,
  PathologyDTO,
  PatientMinimalDTO,
  PatientWorkflow,
  ReportDTO,
  ReportingPatientDTO,
  ReportingTask,
  ReportingTaskDetailsDTO,
  ReportingTaskDTO,
  TargetDocument,
  Technique,
  TemplateModelDTO,
  ViewerDTO,
  WorkflowItem,
} from "../../model";
import { FormControl } from "@angular/forms";
import {
  AudioPlayComponent,
  BookletPrintComponent,
  buildExamData,
  buildPatientDate, buildRadiologistData,
  DeleteConfirmComponent,
  deleteItemFromArray,
  DynamicFormComponent,
  FileElement,
  getAppType,
  PerformerAssignComponent,
  PrintCountComponent,
  removeLocalPatientData,
  SharedService,
  SmsSenderComponent,
  SpeechNoteComponent,
  TechniqueEditComponent,
  TemplateSearchComponent,
  updateVariableValue,
} from '../../shared';
import { EmailSendComponent } from "../email-send/email-send.component";
import { PathologyComponent } from "../pathology/pathology.component";
import { AudioRecorderComponent } from "../audio-recorder/audio-recorder.component";
import { AudioPlayerComponent } from "../audio-player/audio-player.component";
import {
  AsyncSubject,
  forkJoin,
  Observable,
  of,
  of as observableOf,
  Subscription,
} from "rxjs";
import moment from "moment";
import { RouterExtService } from "../../router-ext.service";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { TranslateService } from "@ngx-translate/core";
import { PatientService } from "../../patient/patient.service";
import { AppConfigService } from "../../app-config.service";
import { DomSanitizer } from "@angular/platform-browser";
import { variables } from "../../utils/editor";
import { WsService } from "../../ws.service";
import { MatDrawer } from "@angular/material/sidenav";
import { SchedulerService } from "../../scheduler/scheduler.service";
import { StudyViewerComponent } from "../../shared/study-viewer/study-viewer.component";
import { PrintingHistoryComponent } from "../../shared/printing-history/printing-history.component";
import { WorkflowService } from "../../workflow/workflow.service";
import { SettingService } from "../../setting/setting.service";
import { FileSystemService } from "../../statistic/file-system.service";

const PRINT_COUNT = "print_counts";
const REPORTING_EDIT_URL = "/reporting/report-edition/";
const SVG_IMAGES = "../../assets/images/svg";
const IMAGES = "../../assets/images";
const PREV_URL = "rep_pev_url";

function officeSaved(payload: any): boolean {
  return (
    (payload.data["error"] === 0 && payload.fileSaved) ||
    payload.data["error"] === 4
  );
}

@Component({
  selector: "ft-reporting-edit",
  templateUrl: "./reporting-edit.component.html",
  styleUrls: ["./reporting-edit.component.scss"],
})
export class ReportingEditComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  static count = 0;

  public examVariables: any[];
  public patientVariables: any[];
  public reportEmpty: string;
  @ViewChild("drawer") drawer: MatDrawer;
  reportDialogVisible: boolean;
  reportDialogData: ReportingTaskDTO;
  forcedDisplayDialog: boolean;
  public examData: any;
  public patientData: any;
  public ccData = [];
  public templateModelControl = new FormControl("");
  options: string[] = ["One", "Two", "Three"];
  filteredTemplateModels: TemplateModelDTO[] = [];
  subs: Subscription[] = [];
  @ViewChild(DynamicFormComponent, { static: false })
  dynamicForm: DynamicFormComponent;
  patient: any;
  procedure: any;
  isHide = true;
  selectedReport: ReportDTO;
  reportingTaskDetail: ReportingTaskDetailsDTO;
  taskType: string;
  dictations: DictationDTO[] = [];
  labels: LabelDTO[] = [];
  pathologies: PathologyDTO[] = [];
  images: Image[] = [];
  selectedImages: Set<{ name: string; url: string }> = new Set();
  pathologyControl = new FormControl();
  studyInstanceUID: string;
  profile: any;
  reportingTasks: ReportingTaskDetailsDTO[] = [];
  group = "form";
  userId: number;
  canAssign: boolean;
  patientID: string;
  pacsPatientID: string;

  osirixAETitle: string;
  examDate: string;
  releaseDate: string = "-";
  printers: any[] = [];
  waitingTimeBeforeExit: number;
  generalSetting: GeneralSettingDTO;
  patientDetails: PatientMinimalDTO;
  patientExpand: boolean = false;

  panel: string;
  countDefault = {};
  techniqueControl = new FormControl();
  dateTimeFormat = this._config.dateTimeFormat;
  expanded: boolean = false;
  modelChanged: boolean;
  filteredTechniques: Technique[] = [];
  private defaultViewer: ViewerDTO;
  private username: any;
  private documentKey: string;
  private reportSaved: AsyncSubject<boolean> = new AsyncSubject<boolean>();
  private reportSaveSub$: Subscription;
  private wsSub$: Subscription;
  public editorIsReady: boolean;

  private defaultExitTime = 60 * 60 * 1000;
  readonly appType: "ris" | "cvis";
  workflowItem: PatientWorkflow | WorkflowItem;

  labelControl = new FormControl<string>("");
  showLabelInput = false;
  showPopup = false;
  radiologistData: any = [];

  private static printForceSavingResult(msg: any): void {
    let message: string;
    switch (msg["error"]) {
      case 0:
        message = "Toutes les modification sont enregistées.";
        break;
      case 4:
        message = "Aucune modification ajoutée.";
        break;
      default:
        message = "Error.";
    }
    console.log("%c" + message, "color:cyan;font-weight:bold;");
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: ReportingService,
    private _wfService: WorkflowService,
    private snackBar: MatSnackBar,
    private patientService: PatientService,
    private translate: TranslateService,
    private _config: AppConfigService,
    private _sanitizer: DomSanitizer,
    private scheduleService: SchedulerService,
    private shared: SharedService,
    private _setting: SettingService,
    private routerExtService: RouterExtService,
    private _ws: WsService,
    private _fs: FileSystemService,
    private dialog: MatDialog
  ) {
    this.appType = getAppType(this._config.logo);
    removeLocalPatientData();

    setTimeout(() => {
      this.wsSub$ = this._ws.observeTopic("report").subscribe((res) => {
        const key = res.response;

        if (key === this.documentKey) {
          this.reportSaved.next(true);
          this.reportSaved.complete();
          this.snackBar.open(this.translate.instant("REPORT_SAVED"), "", {
            duration: 2000,
          });
        }

        if (res.response === "Error") {
          this.reportSaved.next(false);
          this.reportSaved.complete();
          this.snackBar.open("Erreur: CR n'est pas enregistré", "Compris!", {
            duration: 3500,
          });
        }
      });
    }, 2000);

    const user = JSON.parse(localStorage.getItem("user"));
    this.profile = user.profile;
    this.userId = user.id;
    this.username = user.username;

    this.waitingTimeBeforeExit = this.defaultExitTime;

    this.examVariables = variables("EXAM_VARIABLES", this);
    this.patientVariables = variables("PATIENT_VARIABLES", this);
  }

  public get keyImagesCount(): number {
    return this.selectedImages && this.selectedImages.size != 0
      ? this.selectedImages.size
      : null;
  }

  public get filesAttachedCount(): number {
    return this.patientDetails &&
      this.patientDetails.fileElements &&
      this.patientDetails.fileElements.length != 0
      ? this.patientDetails.fileElements.length
      : null;
  }

  public get dictationsCount(): number {
    return this.dictations && this.dictations.length > 0
      ? this.dictations.length
      : null;
  }

  public get approved(): boolean {
    return (
      this.editorIsReady &&
      this.reportingTaskDetail &&
      ["SIGNED", "VALIDATED", "VERIFIED"].includes(
        this.reportingTaskDetail.reportingTask.reportingStatus
      )
    );
  }

  public get historyCount(): number {
    if (this.reportingTasks && this.reportingTasks.length !== 0)
      return this.reportingTasks.length;
    return null;
  }

  hasChild = (_: number, node: SRFlatNode) => node.expandable;

  deleteDictation(d: DictationDTO) {
    this.dialog
      .open(DeleteConfirmComponent)
      .afterClosed()
      .subscribe((ok) => {
        if (ok) deleteItemFromArray(this.dictations, d);
      });
  }

  public addPathology = () =>
    this.dialog
      .open(PathologyComponent, { disableClose: true })
      .afterClosed()
      .subscribe((_) => this.getPathologies());

  public selectPathology() {
    this.reportingTaskDetail.reportingTask.pathology =
      this.pathologyControl.value.join(",");
  }

  public onSelectTemplate(event: any) {
    this.modelChanged = true;

    const template = find(this.filteredTemplateModels, {
      name: event.option.value,
    });

    this.selectedReport.templateModelId = template?.id;
    this.selectedReport.name = template.name;

    this.drawer.close().then((_) => this.selectReport(this.selectedReport));
  }

  public startDictation = () =>
    this.subs.push(
      this.dialog
        .open(AudioRecorderComponent, {
          disableClose: true,
          position: { top: "0px" },
          hasBackdrop: false,
        })
        .afterClosed()
        .subscribe((data) => {
          if (data && data.file) {
            this.service
              .saveDictation({
                id: 0,
                recordingDate: new Date(),
                duration: 0,
                record: data.file,
                reportId: this.selectedReport.id,
              })
              .subscribe((dictation) => {
                this.dictations.push(dictation);
                this.dictations = reverse(
                  sortBy(this.dictations, "recordingDate")
                );
              });
          }
        })
    );

  public showDictation(dictation: DictationDTO) {
    this.dialog.open(AudioPlayerComponent, {
      data: dictation,
      hasBackdrop: false,
      position: { left: "0" },
    });
  }

  searchReportTemplate() {
    this.dialog
      .open(TemplateSearchComponent, { minWidth: "50%", height: "500px" })
      .afterClosed()
      .subscribe((template) => {
        if (template) {
          this.templateModelControl.patchValue(template.name);
          this.selectedReport.templateModelId = template?.id;
          this.selectedReport.name = template.name;

          this.drawer
            .close()
            .then((_) => this.selectReport(this.selectedReport));
        }
      });
  }

  displayPrintingHistory() {
    this.dialog.open(PrintingHistoryComponent, {
      data: {
        documentId: this.reportingTaskDetail.reportingTask.accessionNumber,
        targetDocuments: [TargetDocument.A4_REPORT, TargetDocument.BOOKLET],
      },
    });
  }

  public saveReportAndExit() {
    this.service.closeViewerStudy(
      this.reportingTaskDetail.reportingTask.studyInstanceUID
    );
    this.saveReport();
    setTimeout(() => this.onClose(), 2000);
  }

  public openImageGallery() {
    this.isHide = !this.isHide;
    document.getElementById("sidenav").classList.toggle("visible");
  }

  public closeImageGallery() {
    this.isHide = !this.isHide;

    document.getElementById("sidenav").classList.toggle("visible");
  }

  onClose = (force: boolean = false) => {
    if (force) {
      this.dialog
        .open(DeleteConfirmComponent, {
          data: this.translate.instant("CLOSE_CONFIRM"),
        })
        .afterClosed()
        .subscribe((ok) => {
          if (ok)
            this.router
              .navigate(["/workflow"])
              .then((_) => localStorage.removeItem(PREV_URL));
        });
    } else
      this.router
        .navigate(["/workflow"])
        .then((_) => localStorage.removeItem(PREV_URL));
  };

  showImage(image: any) {
    const selectedImageUrl = image.url.substring(image.url.indexOf("kos/"));

    const images = [];
    this.selectedImages.forEach((img) => {
      const imUrl = img.url.substring(img.url.indexOf("kos/"));
      const sp = imUrl.split("/");
      images.push({
        name: sp[sp.length - 1],
        url: "/" + imUrl,
      });
    });

    this.subs.push(
      this.dialog
        .open(StudyViewerComponent, {
          data: {
            images,
            already_selected: true,
            selected: {
              name: image.name,
              url: "/" + selectedImageUrl,
            },
          },
          minWidth: "100vw",
          minHeight: "100vh",
        })
        .afterClosed()
        .pipe(first())
        .subscribe()
    );
  }

  deleteKos(image: { name: string; url: string }) {
    this.selectedImages.forEach((im) => {
      if (im.url === image.url) this.selectedImages.delete(im);
    });
  }

  public printBooklet() {
    this.delayFunctionExecution(() => {
      this.subs.push(
        this.sendForceSave(this.documentKey).subscribe((response) => {
          ReportingEditComponent.printForceSavingResult(response.data);

          if (officeSaved(response)) {
            this.subs.push(
              this.dialog
                .open(BookletPrintComponent, {
                  data: {
                    generalSetting: this.generalSetting,
                    reportingTask: this.reportingTaskDetail.reportingTask,
                  },
                  minWidth: "100vw",
                  minHeight: "100vh",
                  disableClose: true,
                })
                .afterClosed()
                .subscribe((bookletData) => {
                  let instancesUIDs = "";

                  if (bookletData) {
                    this.reportingTaskDetail.reportingTask.numberOfImagesPerPage =
                      bookletData.numberOfImagesPerPage;
                    this.reportingTaskDetail.reportingTask.imageBackground =
                      bookletData.imageBackground;
                    this.reportingTaskDetail.reportingTask.printOption =
                      bookletData.printOption;
                    this.reportingTaskDetail.reportingTask.layout =
                      bookletData.layout;
                    bookletData.images?.forEach(
                      (img: any) =>
                        (instancesUIDs +=
                          img.url.replace("images/", "kos/") + ";")
                    );
                  } else return;

                  this.selectedReport.selectedInstances = instancesUIDs;
                  this.reportingTaskDetail.report = this.selectedReport;

                  this.subs.push(
                    this.service
                      .saveReportingTaskDTO(this.reportingTaskDetail)
                      .subscribe((reportingTask) => {
                        this.initViewData(reportingTask);

                        const matSnackBarRef = this.snackBar.open(
                          this.translate.instant("PRINTING_IN_PROGRESS"),
                          "",
                          { duration: 12000 }
                        );

                        setTimeout(() => {
                          if (this.generalSetting.reportPrintMode === "CHROME")
                            this.subs.push(
                              this.service
                                .printReport(reportingTask.reportingTask.id)
                                .subscribe((_) => matSnackBarRef.dismiss())
                            );
                          else {
                            this.subs.push(
                              this.service
                                .printCupsReport(
                                  reportingTask.reportingTask.id,
                                  bookletData.printer,
                                  bookletData.printCount
                                )
                                .subscribe((response) => {
                                  if (response["status"] !== "ok")
                                    alert("Cannot print the booklet");
                                  else {
                                    matSnackBarRef.dismiss();
                                    this.snackBar.open(
                                      this.translate.instant(
                                        "FINALIZING_PRINTING"
                                      ),
                                      "",
                                      {
                                        duration: 4000,
                                      }
                                    );
                                  }
                                })
                            );
                          }
                        }, 1000);
                      })
                  );

                  this.reportSaved.next(false);
                  this.reportSaved.complete();
                })
            );
          }
        })
      );
    }, 1500);
  }

  public selectReport = (report: ReportDTO) => {
    if (report) {
      this.selectedReport = report;
      this.templateModelControl.patchValue(report.templateModelName);

      this.selectedReport.locked =
        this.reportingTaskDetail.reportingTask.reportingStatus === "SIGNED" &&
        !this.hasPermission("editSignedReport");

      if (this.selectedReport.locked) {
        this.pathologyControl.disable();
        this.templateModelControl.disable();
        this.techniqueControl.disable();
      } else {
        this.pathologyControl.enable();
        this.templateModelControl.enable();
        this.techniqueControl.enable();
      }

      if (this.selectedReport.locked)
        this.snackBar.open(
          this.translate.instant("CAN_NOT_MODIFY_DELIVERED"),
          "",
          { duration: 3000 }
        );
    }
  };

  ngAfterViewInit(): void {
    setTimeout(
      () =>
        (this.canAssign =
          this.reportingTaskDetail &&
          this.userId ===
            this.reportingTaskDetail.reportingTask.performerNameId),
      1000
    );

    this.subs.push(
      this.shared.getPrinters().subscribe((data) => {
        this.printers = data;
        this.getPrintCounts(data);
      })
    );

    if (!localStorage.getItem(PREV_URL))
      localStorage.setItem(PREV_URL, this.routerExtService.getPreviousUrl());
    this.templateModelControl.patchValue("");
  }

  public printReport(
    printer: string,
    mode: "CUPS" | "CHROME" = "CUPS",
    withLink: boolean = false
  ) {
    const count = printer ? get(this.countDefault, printer, 1) : 1;
    this.reportingTaskDetail.report = this.selectedReport;
    this.setKeyImagesToCurrentReport();

    this.subs.push(
      this.sendForceSave(this.documentKey).subscribe((saveResult) => {
        ReportingEditComponent.printForceSavingResult(saveResult.data);

        if (officeSaved(saveResult)) {
          this.subs.push(
            this.service
              .saveReportingTaskDTO(this.reportingTaskDetail)
              .subscribe((reportingTask) => {
                this.initViewData(reportingTask);

                const matSnackBarRef = this.snackBar.open(
                  this.translate.instant("PRINTING_IN_PROGRESS"),
                  "",
                  { duration: 10000 }
                );

                setTimeout(() => {
                  if (mode === "CHROME")
                    this.subs.push(
                      this.service
                        .printSimpleReport(
                          reportingTask.reportingTask.id,
                          printer,
                          count,
                          withLink
                        )
                        .subscribe((_) => matSnackBarRef.dismiss())
                    );
                  else {
                    this.subs.push(
                      this.service
                        .printCupsSimpleReport(
                          reportingTask.reportingTask.id,
                          printer,
                          count
                        )
                        .subscribe((res) => {
                          matSnackBarRef.dismiss();
                          if (res["status"] !== "ok")
                            alert("Cannot print the report");
                          else
                            this.snackBar.open(
                              this.translate.instant("FINALIZING_PRINTING"),
                              "",
                              { duration: 3000 }
                            );
                        })
                    );
                  }

                  this.reportSaved.next(false);
                  this.reportSaved.complete();
                }, 400);
              })
          );
        }
      })
    );
  }

  private getDatasets(): void {
    const datasets = "pathologies,viewers,generalSetting";
    this.shared.getDatasets(datasets).subscribe((data) => {
      datasets.split(",").forEach((it) => (this[it] = data[it]));

      this.osirixAETitle = this["viewers"]
        .filter((v) => v.osirix)
        .map((it) => it.name)[0];
      this.defaultViewer = this["viewers"].filter((v) => v.defaultViewer)[0];
    });
  }

  ngOnInit() {
    this.getDatasets();
    this.queryLabels();

    this.subs.push(
      this.route.params
        .pipe(
          switchMap((params: Params) =>
            this.service.getReportingTaskDetails(+params["id"])
          )
        )
        .subscribe((reportingTaskDetails) =>
          this.initViewData(reportingTaskDetails)
        )
    );

    window.addEventListener(
      "keydown",
      (e) => this.service.keyboardEventSubject.next(e),
      true
    );

    this.subs.push(
      this.techniqueControl.valueChanges
        .pipe(
          startWith(""),
          switchMap(() => {
            const query = this.techniqueControl.value;
            return this.shared.getPaginatedTechniques(
              10,
              0,
              "value",
              "asc",
              query
            );
          }),
          map((data) => data["content"]),
          catchError(() => {
            return observableOf([]);
          })
        )
        .subscribe((data) => (this.filteredTechniques = data))
    );

    this.techniqueControl.patchValue("");
  }

  public sendMail() {
    const emailData = {
      id: this.selectedReport.id,
      patientName: this.reportingTaskDetail.reportingTask.patientName,
      patientEmail: this.reportingTaskDetail.reportingTask.patientEmail,
      referringPhysicianEmail:
        this.reportingTaskDetail.reportingTask.referringPhysicianEmail,
      reportTitle: this.selectedReport.name,
    };

    this.subs.push(
      this.dialog
        .open(EmailSendComponent, {
          width: "600px",
          data: emailData,
          hasBackdrop: false,
          disableClose: true,
          position: { bottom: "0", right: "80px" },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            const status = get(result, "service");
            if (status !== "ERROR") {
              this.snackBar.open(this.translate.instant("EMAIL_SENT"), "", {
                duration: 2500,
              });
              this.subs.push(
                this.scheduleService
                  .updateReportingTaskStatus(
                    this.reportingTaskDetail.reportingTask.id,
                    "DELIVERED"
                  )
                  .subscribe()
              );
            }
          }
        })
    );
  }

  private sendForceSave(key: string): Observable<any> {
    return this.service.sendCmd(new CommandService(key)).pipe(
      mergeMap((data) => {
        if (data["error"] === 4) return of({ data, fileSaved: false });
        return this.reportSaved.pipe(
          mergeMap((fileSaved) => {
            return of({ data, fileSaved });
          })
        );
      })
    );
  }

  public validateReport = (force_close: boolean = true) => {
    this.delayFunctionExecution(() => {
      this.reportingTaskDetail.report = this.selectedReport;
      this.subs.push(
        this.sendForceSave(this.documentKey).subscribe((saveResult) => {
          ReportingEditComponent.printForceSavingResult(saveResult.data);

          if (officeSaved(saveResult)) {
            this.setKeyImagesToCurrentReport();

            setTimeout(() => {
              this.subs.push(
                this.service
                  .validateReportingTaskDTO(this.reportingTaskDetail)
                  .subscribe((value) => {
                    this.reportingTaskDetail = value;
                    this.snackBar.open(
                      this.translate.instant("REPORT_VALIDATED"),
                      "",
                      { duration: 2000 }
                    );
                    if (force_close) this.closeReport(value.reportingTask);
                  })
              );
            }, 1000);

            this.reportSaved.next(false);
            this.reportSaved.complete();
          } else
            this.snackBar.open(
              this.translate.instant("NOT_SAVED_OR_NO_CHANGES"),
              "",
              { duration: 3000 }
            );
        })
      );
    }, 1500);
  };

  public saveReport(
    same: boolean = true,
    aux: ReportingTaskDTO | ReportingTask = null
  ) {
    this.delayFunctionExecution(() => {
      this.reportingTaskDetail.report = this.selectedReport;
      this.subs.push(
        this.sendForceSave(this.documentKey).subscribe((res) => {
          ReportingEditComponent.printForceSavingResult(res.data);

          if (officeSaved(res)) {
            this.setKeyImagesToCurrentReport();

            setTimeout(() => {
              this.subs.push(
                this.service
                  .saveReportingTaskDTO(this.reportingTaskDetail)
                  .subscribe((reportingTask) => {
                    this.snackBar.open(
                      this.translate.instant("REPORT_SAVED"),
                      "",
                      { duration: 2000 }
                    );
                    if (same) this.initViewData(reportingTask);
                    else {
                      this.selectedReport = null;

                      this.navigateToTask(aux.id);
                    }

                    this.reportSaved.next(false);
                    this.reportSaved.complete();
                  })
              );
            }, 1000);
          }
        })
      );
    }, 1500);
  }

  assignPerformer(row: ReportingTaskDTO) {
    this.dialog.open(PerformerAssignComponent, {
      data: { task: row, title: "DELEGATE_TASK" },
      minWidth: "380px",
    });
  }

  public setToReview = (force_close: boolean = true) => {
    this.delayFunctionExecution(() => {
      this.reportingTaskDetail.report = this.selectedReport;
      this.subs.push(
        this.sendForceSave(this.documentKey).subscribe((res) => {
          ReportingEditComponent.printForceSavingResult(res.data);
          if (officeSaved(res)) {
            setTimeout(() => {
              this.subs.push(
                this.service
                  .toReviewReportingTaskDTO(this.reportingTaskDetail)
                  .subscribe((res) => {
                    this.reportingTaskDetail = res;
                    if (force_close) this.closeReport(res.reportingTask);
                  })
              );

              this.reportSaved.next(false);
              this.reportSaved.complete();
            }, 1000);
          } else
            this.snackBar.open(
              this.translate.instant("NOT_SAVED_OR_NO_CHANGES"),
              "",
              { duration: 3000 }
            );
        })
      );
    }, 1500);
  };

  editCount(printer: any) {
    this.subs.push(
      this.dialog
        .open(PrintCountComponent, {
          disableClose: true,
          data: this.countDefault[printer],
        })
        .afterClosed()
        .subscribe((count) => {
          if (count != 0) {
            this.countDefault[printer] = count;
            localStorage.setItem(
              PRINT_COUNT,
              JSON.stringify(this.countDefault)
            );
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.exitEditing();
    localStorage.removeItem(PREV_URL);
    removeLocalPatientData();
    this.subs.forEach((sub) => sub.unsubscribe());
    if (this.reportSaveSub$) this.reportSaveSub$.unsubscribe();
    if (this.wsSub$) this.wsSub$.unsubscribe();
  }

  onChangeTechnique(event: MatAutocompleteSelectedEvent) {
    const technique = event.option.value;
    this.techniqueControl.patchValue(technique.value);

    this.reportingTaskDetail.reportingTask.techniqueId = technique?.id;

    this.updateLocalValue(technique);
  }

  closeReport(rt: ReportingTaskDTO, forceClose: boolean = false) {
    this.subs.push(
      this.service.closeViewerStudy(rt.studyInstanceUID).subscribe()
    );
    if (this.reportingTaskDetail.reportingTask.id === rt.id && !!forceClose)
      this.saveReportAndExit();
    else this.onClose();
  }

  addTechnique() {
    this.subs.push(
      this.dialog
        .open(TechniqueEditComponent)
        .afterClosed()
        .subscribe((res) => {
          if (res) this.techniqueControl.patchValue(res.value);
        })
    );
  }

  openViewer() {
    if (this.defaultViewer.name.toLowerCase().includes("other")) {
      window.open(
        this.defaultViewer.host +
          this.defaultViewer.remotePath +
          this.studyInstanceUID,
        "_blank"
      );
    } else {
      const params = `${this.defaultViewer.name}_${this.studyInstanceUID}`;

      if (window["viewerWindow"] && !window["viewerWindow"].closed) {
        window["viewerWindow"].focus();
        this.subs.push(
          this.service
            .openStudy(this.studyInstanceUID, this.username, false)
            .subscribe()
        );
      } else {
        window["viewerWindow"] = window.open(
          `/external-viewer/study?param=${params}`,
          "viewerWindow",
          "toolbar=0,location=0,menubar=0,left"
        );
        window["viewerWindow"].addEventListener(
          "beforeunload",
          () => (window["viewerWindow"] = null)
        );
      }
    }
  }

  editorReady(docKey: string) {
    this.editorIsReady = true;
    this.documentKey = docKey;
    this.selectReport(this.selectedReport);
  }

  getPatientImage() {
    let defaultImage: string;
    switch (this.patient.patientSex) {
      case "M":
        defaultImage = `${IMAGES}/man.png`;
        break;
      case "F":
        defaultImage = `${IMAGES}/woman.jpg`;
        break;
      default:
        defaultImage = `${IMAGES}/other.jpeg`;
        break;
    }

    return this._sanitizer.bypassSecurityTrustResourceUrl(
      this.patient.profilePicture || defaultImage
    );
  }

  public setToSign = (force_close: boolean = true) => {
    this.delayFunctionExecution(() => {
      this.reportingTaskDetail.report = this.selectedReport;

      this.subs.push(
        this.sendForceSave(this.documentKey).subscribe((res) => {
          ReportingEditComponent.printForceSavingResult(res.data);
          if (officeSaved(res)) {
            setTimeout(() => {
              this.subs.push(
                this.service
                  .toSignReportingTaskDTO(this.reportingTaskDetail)
                  .subscribe((value) => {
                    this.reportingTaskDetail = value;
                    if (force_close) this.closeReport(value.reportingTask);
                  })
              );
              this.reportSaved.next(false);
              this.reportSaved.complete();
            }, 1000);
          } else
            this.snackBar.open(
              this.translate.instant("NOT_SAVED_OR_NO_CHANGES"),
              "",
              { duration: 3000 }
            );
        })
      );
    }, 1500);
  };

  getFileIcon(fileType: string): string {
    switch (fileType) {
      case "WAV":
        return SVG_IMAGES + "/wav.svg";
      case "PDF":
        return SVG_IMAGES + "/007-pdf-1.svg";
      case "PNG":
        return SVG_IMAGES + "/011-png-1.svg";
      case "JPG":
        return SVG_IMAGES + "/008-jpg.svg";
      case "JPEG":
        return SVG_IMAGES + "/008-jpg.svg";
      case "XLS":
        return SVG_IMAGES + "/004-excel.svg";
      case "XLSX":
        return SVG_IMAGES + "/004-excel.svg";
      case "TXT":
        return SVG_IMAGES + "/016-txt.svg";
      case "DOC":
        return SVG_IMAGES + "/003-word.svg";
      case "DOCX":
        return SVG_IMAGES + "/003-word.svg";
      default:
        return SVG_IMAGES + "/016-txt.svg";
    }
  }

  getPatient() {
    this.expanded = false;

    if (!this.patientDetails)
      this.subs.push(
        forkJoin([
          this.patientService.getPatientByID(
            this.reportingTaskDetail.reportingTask.patientID
          ),
          this.patientService.getPatientFiles(
            this.reportingTaskDetail.reportingTask.patientID
          ),
        ]).subscribe((data) => {
          [this.patientDetails, this.patientDetails.fileElements] = data;
          this.expanded = true;
        })
      );
    else if (this.patientExpand) this.expanded = false;
    else setTimeout(() => (this.expanded = !this.expanded), 400);
  }

  public isActive(rt: ReportingTaskDTO): boolean {
    return (
      this.reportingTaskDetail &&
      rt.id === this.reportingTaskDetail.reportingTask.id
    );
  }

  downloadFile(file: FileElement) {
    const filename = file.uuid + "." + file.fileType.toLowerCase();
    window.open(`/api/documents/download/${filename}`, "_blank");
  }

  openElement(element: FileElement) {
    if (["PNG", "JPG", "JPEG"].includes(element.fileType))
      this._fs.openImage(element).subscribe();

    if (["MP3", "WAV", "AAC"].includes(element.fileType)) {
      this.subs.push(
        this.dialog
          .open(AudioPlayComponent, {
            data: {
              audioFiles: this.patientDetails.fileElements.filter(
                (elm: FileElement) =>
                  ["MP3", "WAV", "AAC"].includes(elm.fileType)
              ),
              selected: element,
            },
            minWidth: "80%",
            height: "80%",
            panelClass: "audio-play",
          })
          .afterClosed()
          .subscribe(console.log)
      );
    }

    if (element.fileType === "TXT")
      this.subs.push(
        this.dialog
          .open(SpeechNoteComponent, {
            data: element,
            panelClass: "speech-panel",
            disableClose: true,
          })
          .afterClosed()
          .subscribe()
      );

    if (
      ["DOCX", "XLSX", "DOC", "XLS", "PPT", "PPTX"].includes(element.fileType)
    )
      open(`/doc-reader/${element.uuid}.${element.fileType.toLowerCase()}`);

    if (element.fileType === "PDF")
      this._fs
        .open(`/upload-dir/${element.uuid}.${element.fileType.toLowerCase()}`)
        .subscribe();
  }

  public setToTranscribe(force_close: boolean = true) {
    this.delayFunctionExecution(() => {
      this.reportingTaskDetail.report = this.selectedReport;
      this.subs.push(
        this.sendForceSave(this.documentKey).subscribe((res) => {
          ReportingEditComponent.printForceSavingResult(res.data);

          if (officeSaved(res)) {
            setTimeout(() => {
              this.subs.push(
                this.service
                  .toTranscribeReportingTaskDTO(this.reportingTaskDetail)
                  .subscribe((value) => {
                    this.reportingTaskDetail = value;
                    if (force_close) this.closeReport(value.reportingTask);
                  })
              );
            }, 1000);

            this.reportSaved.next(false);
            this.reportSaved.complete();
          } else
            this.snackBar.open(
              this.translate.instant("NOT_SAVED_OR_NO_CHANGES"),
              "",
              { duration: 4000 }
            );
        })
      );
    }, 1500);
  }

  public signReport(force_close: boolean = true): void {
    this.delayFunctionExecution(() => {
      this.reportingTaskDetail.report = this.selectedReport;

      this.subs.push(
        this.sendForceSave(this.documentKey).subscribe((res) => {
          ReportingEditComponent.printForceSavingResult(res.data);

          if (officeSaved(res)) {
            setTimeout(() => {
              this.subs.push(
                this.service
                  .signReportingTaskDTO(this.reportingTaskDetail)
                  .subscribe((value) => {
                    this.reportingTaskDetail = value;
                    this.snackBar.open(
                      this.translate.instant("REPORT_SIGNED"),
                      "",
                      { duration: 2000 }
                    );
                    if (force_close) this.closeReport(value.reportingTask);
                  })
              );
            }, 1000);

            this.reportSaved.next(false);
            this.reportSaved.complete();
          } else
            this.snackBar.open(
              this.translate.instant("NOT_SAVED_OR_NO_CHANGES"),
              "",
              { duration: 3000 }
            );
        })
      );
    }, 1500);
  }

  public setToValidate = (force_close: boolean = true): void => {
    this.delayFunctionExecution(() => {
      this.reportingTaskDetail.report = this.selectedReport;

      this.sendForceSave(this.documentKey).subscribe((res) => {
        ReportingEditComponent.printForceSavingResult(res.data);
        if (officeSaved(res)) {
          setTimeout(() => {
            this.subs.push(
              this.service
                .toValidateReportingTaskDTO(this.reportingTaskDetail)
                .subscribe((value) => {
                  this.reportingTaskDetail = value;
                  if (force_close) this.closeReport(value.reportingTask);
                })
            );
          }, 1000);

          this.reportSaved.next(false);
          this.reportSaved.complete();
        } else
          this.snackBar.open(
            this.translate.instant("NOT_SAVED_OR_NO_CHANGES"),
            "",
            { duration: 3000 }
          );
      });
    }, 1500);
  };

  // delay execution of a given function by a given timeout
  private delayFunctionExecution(fn: () => void, timeout: number): void {
    const _snackBarRef = this.snackBar.open(
      this.translate.instant("SAVING"),
      "",
      { duration: 2000 }
    );
    setTimeout(() => {
      // call the input function here...
      fn();
      _snackBarRef.dismiss();
    }, timeout);
  }

  openSelectedReportingTask(
    rt: ReportingTaskDTO | ReportingTask,
    waiting: boolean = false
  ) {
    if (this.reportingTaskDetail.reportingTask.id === rt.id) return;
    if (!waiting) this.saveReport(false, rt);
  }

  editTechnique(technique: Technique) {
    this.subs.push(
      this.dialog
        .open(TechniqueEditComponent, { data: technique })
        .afterClosed()
        .subscribe((res) => {
          if (res) this.techniqueControl.patchValue(res.value);
        })
    );
  }

  deleteTechnique(technique: Technique) {
    this.dialog
      .open(DeleteConfirmComponent)
      .afterClosed()
      .subscribe((res) => {
        if (res)
          this.shared
            .deleteTechnique(technique.id)
            .subscribe((_) => this.techniqueControl.patchValue(""));
      });
  }

  insertVariable(key: string) {
    this.service.variableInsert.next(key);
  }

  hasPermission(permission: string): boolean {
    return get(this.profile, permission, "NONE") != "NONE";
  }

  sendSMS() {
    const {
      id,
      patientName,
      procedureCodes: procedureCode,
      patientPhone: patientPhoneNumber,
      scheduledProcedureStepStartDateTime: appointmentDateTime,
    } = this.reportingTaskDetail.reportingTask;

    this.subs.push(
      this.dialog
        .open(SmsSenderComponent, {
          data: {
            id,
            patientName,
            procedureCode,
            patientPhoneNumber,
            appointmentDateTime,
            source: "REPORTING_TASK",
          },
          minWidth: "360px",
        })
        .afterClosed()
        .subscribe()
    );
  }

  showReport(item: ReportingTaskDTO, forced: boolean = false) {
    this.reportDialogVisible = true;
    this.reportDialogData = item;
    this.forcedDisplayDialog = forced;
  }

  closeDialogReport() {
    if (this.forcedDisplayDialog) return;
    this.reportDialogVisible = false;
  }

  onCloseReportPreview(_: any) {
    this.reportDialogVisible = false;
  }

  onKeyImageSelection(kos: string[]) {
    kos.forEach((it) => {
      it = it.replace("images", "kos");
      const sp = it.split("/");
      this.selectedImages.add({
        name: sp[sp.length - 1],
        url: it.substring(it.indexOf("kos/")),
      });
    });
  }

  private getPathologies = () =>
    this.subs.push(
      this.shared
        .getPathologies("NONE")
        .subscribe((data) => (this.pathologies = data))
    );

  private getAllPatientReportingTasks = (
    patientID: string,
    excludeTaskId: number
  ) =>
    this.subs.push(
      this.service
        .getAllPatientReportingTasks(patientID, excludeTaskId)
        .subscribe(
          (data) =>
            (this.reportingTasks = reverse(sortBy(data, "report.lastModified")))
        )
    );

  private fetchSelectedImages(report: ReportDTO) {
    if (report.selectedInstances != null && report.selectedInstances != "")
      report.selectedInstances
        .split(";")
        .filter((it) => it !== "")
        .forEach((it) => {
          const sp = it.split("/");
          this.selectedImages.add({
            name: sp[sp.length - 1],
            url: it,
          });
        });
  }

  private exitEditing() {
    return this.subs.push(
      this.scheduleService
        .exitEditing(this.reportingTaskDetail.reportingTask.id)
        .subscribe()
    );
  }

  private updateLocalValue(technique: Technique) {
    if (technique)
      updateVariableValue("%EXAM::TECHNIQUE%", technique.description);
  }

  private getPrintCounts(data: any) {
    const printersObject = {};
    if (data && data.length !== 0)
      data.map((it) => it.label).forEach((v) => (printersObject[v] = 1));
    this.countDefault =
      JSON.parse(localStorage.getItem(PRINT_COUNT)) || printersObject;
    localStorage.setItem(PRINT_COUNT, JSON.stringify(this.countDefault));
  }

  private filterTemplateModels(): void {
    this.subs.push(
      this.templateModelControl.valueChanges
        .pipe(
          debounceTime(400),
          startWith(""),
          switchMap(() => {
            const query = this.templateModelControl.value;
            return this._setting.queryTemplateModels(
              10,
              0,
              "name",
              "asc",
              query
            );
          }),
          map((data) => data["content"]),
          catchError(() => {
            return observableOf([]);
          })
        )
        .subscribe((data) => (this.filteredTemplateModels = data))
    );

    this.templateModelControl.patchValue("");
  }

  private _startExitTimer(): void {
    setTimeout(() => {
      this.exitEditing();
    }, this.waitingTimeBeforeExit || this.defaultExitTime);
  }

  private _initPatientInfo(patientInfo: ReportingPatientDTO): void {
    this.patientData = buildPatientDate(patientInfo);

    this.patient = assign({
      patientID: patientInfo.externalPatientID || "",
      patientName: patientInfo.fullName || "",
      patientAge: patientInfo.age || "",
      patientSex: patientInfo.sex || "",
      alerts: patientInfo.alerts || "",
      imc: patientInfo.imc || "",
      sc: patientInfo.sc || "",
      id: patientInfo.id || "",
      cin: patientInfo.cin || "",
      phone: patientInfo.phone || "",
      profilePicture: patientInfo.profilePicture || "",
      additionalPatientHistory: patientInfo.additionalPatientHistory || "",
    });
  }

  private initViewData(reportingTaskDetail: ReportingTaskDetailsDTO): void {
    this.reportingTaskDetail = reportingTaskDetail;
    const { reportingTask, report, patient, dictations, labels } =
      reportingTaskDetail;

    this._initPatientInfo(patient);
    this.filterTemplateModels();
    this._startExitTimer();
    this.dictations = dictations;
    this.labels = labels || [];

    this.scheduleService.startEditing(reportingTask.id).subscribe();
    this._setTechniqueAndPathology(reportingTask);

    this.selectReport(report);
    this._buildExamData(reportingTask);

    this.getAllPatientReportingTasks(reportingTask.patientID, reportingTask.id);

    this.fetchSelectedImages(report);

    if (getAppType(this._config.logo) === "cvis")
      this.getWorkflowItem(reportingTask?.accessionNumber);
  }

  private _buildExamData(reportingTask: ReportingTaskDTO): void {
    const technique = this.filteredTechniques.find(
      (it) => this.techniqueControl.value === it.value
    );

    this.studyInstanceUID = reportingTask.studyInstanceUID;
    this.patientID = reportingTask.patientID;
    this.pacsPatientID = reportingTask.pacsPatientID;
    this.examDate = moment(
      reportingTask.scheduledProcedureStepStartDateTime
    ).format("DD/MM/YYYY");
    this.releaseDate = reportingTask.releaseDate
      ? moment(reportingTask.releaseDate).format("DD/MM/YYYY")
      : "-";

    const examInfo = {
      studyInstanceUID: reportingTask.studyInstanceUID,
      pacsPatientID: reportingTask.pacsPatientID,
      examDate: this.examDate,
      signatureDate: this.releaseDate,
      code: reportingTask.procedureCodes,
      referringPhysician: reportingTask.referringPhysicianName,
      referringPhysicianAddress: reportingTask.referringPhysicianAddress,
      technique: technique ? technique.description : "",
      comments: reportingTask.noteAlert
    };

    this.examData = buildExamData(examInfo);

    if (this.profile?.signReport !== 'NONE') {

      this.shared.getPerformingPhysician(reportingTask.performerNameId).subscribe(value => {
        this.radiologistData = buildRadiologistData(value.radiologist);
      });
    }
  }

  private _setTechniqueAndPathology(reportingTask: ReportingTaskDTO): void {
    if (reportingTask.techniqueId)
      this.subs.push(
        this.shared
          .getTechnique(reportingTask.techniqueId)
          .subscribe((value) => {
            this.techniqueControl.patchValue(value.value);
          })
      );

    if (reportingTask.pathology) {
      this.pathologyControl.patchValue(reportingTask.pathology.split(","));
    } else this.pathologyControl.patchValue("");
  }

  private setKeyImagesToCurrentReport(): void {
    this.selectedReport.selectedInstances = "";
    this.selectedImages.forEach(
      (image) => (this.selectedReport.selectedInstances += image.url + ";")
    );
  }

  private navigateToTask(id: number) {
    this.router.navigateByUrl(REPORTING_EDIT_URL + id).then();
  }

  switchToEditorAndCompile(template: any) {
    const ccData = [];
    ccData.push({ tag: template.name, value: template.label });

    template["blocks"]?.forEach((bc: any) => {
      ccData.push({ tag: bc.name, value: bc.label });
      bc.fields?.forEach((field: any) => {
        ccData.push({
          tag: `${bc.name}.${field.name}`,
          value: getFormFieldValue(field) || "-",
        });
      });
    });
    this.ccData = ccData;
    this.group = "template";
  }

  private getWorkflowItem(accessionNumber: string) {
    this._wfService
      .getWorkflowItem(accessionNumber)
      .subscribe((value) => (this.workflowItem = value));
  }

  removeLabel(label: LabelDTO) {
    this.subs.push(
      this.service
        .deleteLabel(this.reportingTaskDetail.reportingTask.id, label.id)
        .subscribe((ok) => {
          if (ok) deleteItemFromArray(this.labels, label);
        })
    );
  }

  addLabel() {
    this.showLabelInput = true;
  }

  queryLabels() {
    this.labelControl.valueChanges.subscribe((value) => {
      console.log(value);
    });
  }

  selectLabel(label: LabelDTO) {
    if (!this.labels.includes(label))
      this.subs.push(
        this.service
          .addLabel(this.reportingTaskDetail.reportingTask.id, label)
          .subscribe((value) => this.labels.push(value))
      );
    this.showLabelInput = false;
  }

  shareViaPortal() {
    const { reportingTask } = this.reportingTaskDetail;

    const shareRequest = {
      study_uid: reportingTask.studyInstanceUID,
      username: reportingTask.referringPhysicianEmail,
      url_expiration_datetime: moment()
        .add(1, "month")
        .format("YYYY-MM-DD HH:mm:ss"),
    };
    this.service.shareViaPortal(shareRequest).subscribe((ok) => {
      if (ok)
        this.snackBar.open(this.translate.instant("portal_shared"), "", {
          duration: 3000,
        });
    });
  }
}

export function getFormFieldValue(field: any): string {
  switch (field.type) {
    case "checkbox":
      return field.value ? Object.keys(field.value).join(",") : field.value;
    case "number":
      return String(field.value || "-");
    case "image":
      return "";
    default:
      return field.value;
  }
}

interface SRFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}
